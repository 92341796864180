<template>
  <div class="el-select relative" :style="{ width: width }" v-clickoutside="handleClose">
    <div :class="[`multiple-select_input el-input--${size}`, { 'multiple-lines': multipleLine }]" @click="openPopper">
      <div class="select_tags-container" ref="selectTags">
        <el-tag
          v-for="item in selected"
          :key="item[props.value]"
          type="info"
          class="selected-tag truncate"
          size="small"
          closable
          disable-transitions
          @close="deleteTag($event, item)"
        >
          {{ item[props.label] }}
        </el-tag>
      </div>
      <span class="select_suffix-icon">
        <i :class="['el-input__icon', 'el-icon-' + iconClass]"></i>
      </span>
    </div>
    <i v-if="showClose" class="el-input__icon iconfont icon-icon_shanchu" @click="handleClearClick"></i>
    <transition name="el-zoom-in-top" @before-enter="handleMenuEnter" @after-leave="doDestroy">
      <div ref="popper" v-show="visible" class="el-select-dropdown el-popper" :style="{ width: width }">
        <slot name="title"></slot>
        <el-scrollbar
          tag="ul"
          wrap-class="el-select-dropdown__wrap"
          view-class="el-select-dropdown__list"
          ref="scrollbar"
          v-show="options.length > 0"
        >
          <li
            v-for="item in options"
            :key="item[props.value]"
            :class="['select-dropdown__option', { 'is-active': item.active }]"
            @click="addTag($event, item)"
          >
            <span>{{ item[props.label] }}</span>
            <i v-show="item.active" class="el-icon-check"></i>
          </li>
        </el-scrollbar>
        <div v-if="options.length === 0" class="el-select-dropdown__empty">无数据</div>
        <div class="popper__arrow"></div>
      </div>
    </transition>
  </div>
</template>

<script>
// components
import { Input, Tag, Scrollbar } from 'element-ui';
// utils
import Clickoutside from 'element-ui/src/utils/clickoutside';
import scrollIntoView from 'element-ui/src/utils/scroll-into-view';

export default {
  name: 'multipleSelect',

  components: {
    [Input.name]: Input,
    [Tag.name]: Tag,
    [Scrollbar.name]: Scrollbar,
  },

  directives: { Clickoutside },

  props: {
    value: Array,
    width: {
      type: String,
      default: '200px',
    },
    size: {
      type: String,
      default: 'small',
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    options: {
      type: Array,
      required: true,
    },
    props: {
      type: Object,
      default: () => ({
        label: 'label',
        value: 'value',
      }),
    },
    filterable: Boolean,
  },

  computed: {
    showClose() {
      return this.selected.length > 0;
    },
    iconClass() {
      return this.visible ? 'arrow-up' : 'arrow-down';
    },
  },

  data() {
    return {
      selected: [],
      visible: false,
      multipleLine: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.selected = val || [];
        this.fixSelectInputStyle();
      },
    },
    selected() {
      this.fixSelectInputStyle();
      this.fixOptionsBySelected();
    },
    options() {
      this.fixOptionsBySelected();
    },
  },

  methods: {
    openPopper() {
      this.visible = !this.visible;
    },

    // option滚动至所选位置
    scrollToOption(option) {
      const key = this.props.value;
      const targetIndex = option[0] ? this.options.findIndex((s) => s[key] === option[0][key]) : null;

      if (this.$refs.popper && targetIndex) {
        const target = this.$refs.scrollbar.$el.getElementsByClassName('select-dropdown__option')[targetIndex];
        const menu = this.$refs.scrollbar.$el.querySelector('.el-select-dropdown__wrap');
        scrollIntoView(menu, target);
      }

      this.$refs.scrollbar && this.$refs.scrollbar.handleScroll();
    },

    handleMenuEnter() {
      this.$nextTick(() => this.scrollToOption(this.selected));
    },

    doDestroy() {},

    handleClose() {
      this.visible = false;
    },

    handleClearClick() {
      this.selected = [];
      this.options.forEach((t) => (t.active = false));
    },

    addTag(event, tag) {
      let key = this.props.value;
      let existOption = this.selected.find((t) => t[key] === tag[key]);

      if (!existOption) {
        this.selected.push(tag);
      } else {
        this.selected.splice(
          this.selected.findIndex((t) => t[key] === tag[key]),
          1
        );
      }
      this.handlerChangeTag();
      event.stopPropagation();
    },

    deleteTag(event, tag) {
      let key = this.props.value;
      this.selected.splice(
        this.selected.findIndex((t) => t[key] === tag[key]),
        1
      );
      this.handlerChangeTag();
      event.stopPropagation();
    },

    // 调整选择框样式
    fixSelectInputStyle() {
      this.$nextTick(() => {
        this.multipleLine = this.$refs.selectTags && this.$refs.selectTags.offsetHeight > 32 ? true : false;
      });
    },

    // 关联选项与选中值
    fixOptionsBySelected() {
      let selectedVals = this.selected.map((t) => t[this.props.value]);
      this.options.forEach((t) => {
        if (selectedVals.includes(t[this.props.value])) {
          t.active = true;
        } else {
          t.active = false;
        }
      });
    },

    handlerChangeTag() {
      const selected = this.selected.map((s) => {
        let selectOption = Object.assign({}, s);
        delete selectOption.active;
        return selectOption;
      });
      this.$emit('input', selected);
    },
  },
};
</script>

<style scoped lang="scss" src="./index.scss"></style>
