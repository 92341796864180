<template>
  <el-dialog
    title="学校清单"
    :visible.sync="visible"
    custom-class="ql-dialog data-push_shool-dialog"
    width="700px"
    :close-on-click-modal="false"
  >
    <el-table :data="currentPageData" class="dialog-table">
      <el-table-column prop="index" label="序号" width="144"></el-table-column>
      <el-table-column prop="schoolName" label="学校名称"></el-table-column>
    </el-table>
    <qlPagination
      @current-change="handleCurrentChange"
      :current-page.sync="pageIndex"
      :page-size="pageSize"
      :total="dataTotal"
      layout="prev, pager, next"
      class="dialog-pagination"
    ></qlPagination>
  </el-dialog>
</template>

<script>
import { Dialog, Table, TableColumn } from 'element-ui';
import qlPagination from '@/components/qlPagination';

export default {
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    qlPagination,
  },

  props: {
    data: Array,
    value: Boolean,
  },

  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
    };
  },

  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },

    dataTotal() {
      return this.data?.length;
    },

    currentPageData() {
      const startIndex = (this.pageIndex - 1) * this.pageSize;
      let dataList =
        this.pageSize * this.pageIndex < this.dataTotal
          ? this.data.slice(startIndex, this.pageIndex * this.pageSize)
          : this.data.slice(startIndex);
      dataList.forEach((item, index) => {
        item.index = startIndex + index + 1;
      });
      return dataList;
    },
  },

  watch: {
    visible: {
      handler: function (val) {
        // reset
        val && Object.assign(this.$data, this.$options.data());
      },
    },
  },

  methods: {
    handleCurrentChange(val) {
      this.pageIndex = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-table {
  border: 1px solid #dee0e3;
}

.dialog-pagination {
  padding: 24px 0;
}

::v-deep {
  .data-push_shool-dialog .el-dialog__body {
    max-height: 70vh;
  }
}
</style>
