// components
import { Button, Table, TableColumn, Dialog, Input, Select, Option, Form, FormItem } from 'element-ui';
import backStageHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import qlPagination from '@/components/qlPagination';
import multipleSelect from '@/components/multipleSelect/index.vue';
import schoolDialog from './components/schoolDialog';
// api
import {
  getDataPushSchoolPage,
  deleteSchoolConfig,
  getDataPushSchoolByName,
  getAllConnectAreaList,
  saveOrUpdateSchoolConfig,
} from '@/api/back-stage';
// utils
import { checkTextOverflowing } from '@/utils/common';

export default {
  name: 'allPassSingleSign',
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    backStageHeader,
    searchInput,
    qlPagination,
    multipleSelect,
    schoolDialog,
  },
  data() {
    return {
      searchName: '',

      // 表格
      listData: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },

      // 学校清单
      schoolDialog: {
        visible: false,
        data: [],
      },

      // 表单
      visible: false,
      loading: false,
      form: {
        id: '',
        schools: [],
        platformName: '',
        platformUrl: '',
      },
      // 选择学校
      // activeIndex: 0, // 关联表单-当前焦点学校项序号
      areaList: [], // 区域平台列表
      schoolList: [],
      originSchools: [], // 未修改前已选学校
      searchForm: {
        appKey: '',
        schoolName: '',
      },
      selectProps: { label: 'schoolName', value: 'schoolId' },
    };
  },
  methods: {
    // 根据名称搜索
    searchData() {
      this.getDataList(true);
    },

    // 加载表格数据
    getDataList(reset = false) {
      reset === true && (this.listData.pageIndex = 1);
      let { pageIndex, pageSize } = this.listData;
      getDataPushSchoolPage({
        pageIndex,
        pageSize,
        platformName: this.searchName,
      }).then((res) => {
        if (res.result) {
          this.listData.list = res.result.data;
          this.listData.total = res.result.recordCount;
          this.checkTextOverflowing(res.result.data);
        } else {
          this.listData.list = [];
          this.listData.total = 0;
        }
      });
    },

    // 获取全部学校名称
    getSchoolNameStr(row) {
      return row.schoolName.replace(/,|，/g, ' | ');
    },

    // 判断全部学校名称是否过长
    checkTextOverflowing(schools) {
      this.$nextTick(() => {
        schools.forEach((school) => {
          const cellChild = this.$refs[`school_${school.id}`];
          if (!checkTextOverflowing(cellChild)) {
            cellChild.nextElementSibling.style.display = 'none';
          } else {
            cellChild.nextElementSibling.style.display = 'block';
          }
        });
      });
    },

    // 切换条数
    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getDataList(true);
    },

    // 切换页码
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getDataList();
    },

    // 查看学校详情
    showSchoolTable(row) {
      this.schoolDialog.data = row.schools;
      this.schoolDialog.visible = true;
    },

    // 新增关联
    handleAdd() {
      this.visible = true;
    },

    // 编辑关联
    handleEdit(row) {
      this.visible = true;
      let { id, platformName, platformUrl, schools } = row;
      this.form = { id, platformName, platformUrl, schools: [] };

      if (schools && schools.length) {
        // 默认已选学校
        this.form.schools = schools.map((s) => {
          let { schoolId, schoolName, areaName } = s;
          return { schoolId, schoolName, areaName };
        });
      }

      // 未修改前已选学校
      this.originSchools = schools.map((s) => {
        let { schoolId, schoolName, areaName } = s;
        return { schoolId, schoolName, areaName };
      });
    },

    // 删除
    handleDelete(row) {
      this.$msgbox
        .confirm('确定删除关联吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          deleteSchoolConfig({
            schoolConfigId: row.id,
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
            // 判断是否为最后一页唯一一条数据
            let idx = this.listData.pageIndex;
            if (idx > 1 && this.listData.list && this.listData.list.length === 1) {
              idx--;
            }
            this.handleCurrentChange(idx);
          });
        })
        .catch(() => {});
    },

    // 获取区域平台列表
    getAreaList() {
      getAllConnectAreaList().then((res) => {
        let areaList = [
          {
            appKey: '',
            areaPlatFormName: '全部区域',
          },
        ];
        this.areaList = areaList.concat(res.result || []);
        this.getSchoolList();
      });
    },

    // 获取可选学校列表
    getSchoolList() {
      let { appKey, schoolName } = this.searchForm;
      getDataPushSchoolByName({ appKey, schoolName }).then((res) => {
        this.schoolList = res.result;
      });
    },

    // 保存
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid && !this.loading) {
          this.loading = true;
          let { id, schools, ...form } = this.form;

          // 修改后已选学校id
          const currentSchoolIds = schools.map((s) => s.schoolId);
          // 修改前已选学校id
          const originSchoolIds = this.originSchools.map((s) => s.schoolId);

          // 修改后已删除学校
          const removeSchools = this.originSchools.filter((s) => !currentSchoolIds.includes(s.schoolId));
          removeSchools.forEach((s) => (s.status = 0));

          // 修改后已添加学校
          const addSchools = schools.filter((s) => !originSchoolIds.includes(s.schoolId));
          addSchools.forEach((s) => (s.status = 1));

          const schoolConfigSchool = addSchools.concat(removeSchools);

          let params = {
            ...form,
            schoolConfigSchool: JSON.stringify(schoolConfigSchool),
          };
          id && (params.id = id);

          saveOrUpdateSchoolConfig(params)
            .then(() => {
              this.$message.success('保存成功！');
              this.visible = false;
              this.getDataList();
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // 表单重置
    resetForm() {
      this.form = {
        id: '',
        schools: [],
        platformName: '',
        platformUrl: '',
      };
      this.originSchools = [];
      this.schoolList = [];
      this.searchForm = {
        appKey: '',
        schoolName: '',
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getDataList();
  },
};
